.highscore-form {
  max-width: 800px;
}

.highscore-form h1 {
  color: var(--color-pink);
  position: relative;
  right: -5px;
}

.highscore-form label {
  display: block;
  color: var(--color-orange);
  font-size: 15px;
  margin-bottom: 10px;
}

.highscore-form input {
  background-color: transparent;
  font: inherit;
  outline: none;
  text-transform: inherit;
  display: block;
  width: 100%;
  color: white;
  border: 2px solid var(--color-yellow);
  padding: 0 10px;
  box-sizing: border-box;
  margin-top: 8px;
  font-size: 40px;
}

.highscore-form input.alias {
  text-transform: uppercase;
}

.highscore-form input::placeholder {
  color: var(--color-blue-link);
}

.highscore-form button {
  display: inline;
  justify-self: right;
  margin: 28px 0;
  padding: 0px 10px 8px 10px;
  line-height: 40px;
  font-size: 40px;
  background: var(--color-pink);
  color: white;
  border-bottom: 2px solid var(--color-blue-link);
  text-transform: uppercase;
}

.highscore-form button:active {
  background: var(--color-blue-link);
}

.highscore-form-columns {
  display: grid;
  margin-top: 50px;
  grid-gap: 40px;
  align-items: baseline;
  grid-template-columns: 9fr 11fr;
}

@media screen and (max-width: 650px) {
  .highscore-form-columns {
    display: block;
  }
}

.highscore-form-columns .left {
  display: grid;
}

.highscore-row {
  display: grid;
  column-gap: 20px;
  grid-template-columns: auto auto 1fr;
}

@media screen and (max-width: 650px) {
  .highscore-row {
    display: block;
  }
  .highscore-row span {
    margin-bottom: 20px;
  }
}

.highscore-row span {
  display: block;
  color: var(--color-blue-light);
  line-height: 50px;
  font-size: 80px;
}

.emojis {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.emojis span {
  color: var(--color-blue-link);
  display: inline-block;
  border: 2px solid var(--color-blue-link);
  padding: 2px 2px 4px 4px;
  margin: 0 6px 6px 0;
  font-size: 35px;
  cursor: pointer;
}

.emojis span:hover,
.emojis span.selected {
  background-color: var(--color-yellow);
  border-color: var(--color-yellow);
}
