body {
  background-color: var(--color-beige-light);
}

::backdrop {
  background-color: var(--color-beige-light);
}

body div#root div.logo a {
  background: url("./../assets/images/logo-beige.svg") center center no-repeat;
}

body.about {
  background-color: var(--color-beige-dark);
}
body.about div#root div.logo a {
  background: url("./../assets/images/logo-white.svg") center center no-repeat;
}

body.highscore {
  background-color: var(--color-blue);
}
body.highscore div#root div.logo a {
  background: url("./../assets/images/logo-yellow-orange.svg") center center
    no-repeat;
}

body.submit-highscore {
  background-color: var(--color-blue-darker);
}
body.submit-highscore div#root div.logo a {
  background: url("./../assets/images/logo-green-orange.svg") center center
    no-repeat;
}
