html,
body {
  font-family: Vollkorn, "Courier New", Courier, monospace;
  font-size: 20px;
  margin: 0;
  padding: 0;
  color: var(--color-beige-dark);
}

strong {
  color: var(--color-beige-light);
}

h1 {
  margin: 0 0 10px 0;
  padding: 0;
  font-family: "Poppkorn";
  text-align: center;
  color: white;
  font-size: 50px;
  letter-spacing: 4px;
}

a,
div {
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

div#root {
  display: grid;
  height: 100vh;
  row-gap: 20px;
  grid-template-rows: 100px 1fr;
}

div#root div.logo {
  display: grid;
  justify-items: center;
}

div#root div.logo a {
  display: block;
  width: 200px;
  height: 100%;
  background-size: contain;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
  font: inherit;
  text-decoration: none;
  text-align: left;
  line-height: inherit;
  outline: none;
  text-transform: inherit;
  &[disabled] {
    cursor: auto;
  }
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

button:disabled,
input:disabled {
  opacity: 0.5;
}
