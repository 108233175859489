.minimap {
  display: flex;
}

.minimap .pixel {
  width: 2px;
  height: 2px;
}

.minimap .pixel.border {
  opacity: 0.5;
  background: var(--color-blue-dark);
}

.minimap .pixel.worm {
  background: var(--color-blue-dark);
}

.minimap .pixel.head {
  background: var(--color-blue-darker);
}

.minimap .pixel.tail {
  background: var(--color-blue-light);
}

@media screen and (max-width: 500px) {
  .minimap .pixel {
    width: 1px;
    height: 1px;
  }
}
