.about-container {
  color: var(--color-blue-darker);
  display: grid;
  grid-template-columns: 1fr 220px 1fr;
  row-gap: 0px;
  grid-template-areas:
    ". image ."
    ". gameinfo ."
    "devinfo devinfo devinfo"
    ". thanks thanks";
}

@media screen and (max-width: 550px) {
  .about-container {
    grid-template-columns: auto;
    grid-template-areas:
      "image"
      "gameinfo"
      "devinfo"
      "thanks";
  }
}

img.kraski {
  justify-self: center;
  grid-area: image;
}

.game-info {
  grid-area: gameinfo;
  text-align: center;
  border: 2px solid var(--color-blue-darker);
  margin: 50px 0 50px 0;
  padding: 10px 0 17px 0;
  border-left: none;
  border-right: none;
}

.dev-info {
  grid-area: devinfo;
}

.dev-info ul {
  display: grid;
  grid-template-columns: 1fr 220px 1fr;
  align-items: baseline;
}

.dev-info ul li:first-child {
  text-transform: uppercase;
  padding-right: 20px;
  text-align: right;
}

.dev-info ul li:nth-child(2) {
  grid-column: 2 / span 2;
  color: white;
  font-size: 30px;
  font-family: "Poppkorn";
}

@media screen and (max-width: 550px) {
  .dev-info ul {
    display: block;
    grid-template-columns: auto;
    margin-bottom: 20px;
  }
  .dev-info ul li span {
    display: block;
  }

  .dev-info ul li:first-child {
    text-align: left;
  }
}

.software-info {
  margin: 10px 0 0 0;
  padding: 0;
  grid-area: thanks;
}

.software-info a,
.dev-info ul li:nth-child(2) a {
  font-size: 20px;
  font-family: "Vollkorn";
  text-transform: uppercase;
  color: var(--color-blue-link);
}
