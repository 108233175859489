.game-container {
  position: relative;
  display: grid;
  align-items: center;
  justify-items: center;
  row-gap: 5px;
  padding: 0 20px;
}

.gamebar {
  align-self: flex-start;
  position: relative;
  display: grid;
  font-size: 26px;
  width: 1036px;
  grid-template-columns: 1fr auto 1fr;
  justify-items: flex-start;
  column-gap: 20px;
  color: var(--color-beige-dark);
}

.gamebar .score-info {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 30px;
}

.gamebar button {
  justify-self: right;
  color: var(--color-beige-dark);
}

.current-highscore {
  transform-origin: 100% 70%;
  display: inline-block;
}

.current-highscore.changing {
  animation: bounce 0.5s ease alternate;
}

@keyframes bounce {
  50% {
    transform: scale(1.3);
  }
}

.sound {
  top: 15px;
  right: 15px;
  color: var(--color-beige-dark);
  transition: all 0.5s ease;
}
.sound.disabled {
  opacity: 0.5;
}

.main {
  align-self: center;
  justify-self: center;
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  padding-bottom: 20px;
}

.main canvas {
  max-width: 100%;
  background: var(--color-canvas);
}
