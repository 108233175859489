:root {
  --color-canvas: #af9980;
  --color-beige-light: #cfcbbb;
  --color-beige-dark: #b09a7f;
  --color-shadow: #00000063;
  --color-blue: #0185b2;
  --color-blue2: #0086f1;
  --color-blue-light: #01cfff;
  --color-blue-link: #3062b2;
  --color-blue-dark: #01608c;
  --color-blue-darker: #003a65;
  --color-yellow: #ffd054;
  --color-pink: #ee557b;
  --color-orange: #f18700;
  --color-grey: #688390;
  --color-grey-deactivated: #d4e1e8;
}

@font-face {
  font-family: "Vollkorn";
  src: url(./../assets/fonts/vollkorn.ttf);
}

@font-face {
  font-family: "Poppkorn";
  src: url(./../assets/fonts/poppkorn.ttf);
}
