.main-menu {
  position: absolute;
  display: grid;
  background: white;

  row-gap: 5px;
  padding-bottom: 20px;
  border: 2px solid var(--color-blue-dark);
  box-shadow: 5px 5px 0px 0px var(--color-shadow);
}

.main-menu h1 {
  display: block;
  line-height: 27px;
  padding-bottom: 8px;
  margin: 0 0 10px 0;
  background: var(--color-blue-dark);
}

.main-menu a,
.main-menu button {
  padding: 0 20px;
  text-transform: uppercase;
  color: var(--color-grey);
}

.main-menu .no-keyboard {
  opacity: 0.5;
}

.main-menu .submit {
  animation: highlighHighscore 1s ease-in-out infinite;
}

@keyframes highlighHighscore {
  50% {
    opacity: 0.5;
  }
}

.main-menu .shortcut {
  text-decoration: underline;
}

.main-menu .shorcutkey {
  display: inline-block;
  position: relative;
  top: -3px;
  font-size: 10px;
  border-radius: 2px 2px 0 0;
  padding: 2px 1px 8px 1px;
  box-sizing: border-box;
  height: 8px;
  line-height: 0px;
  color: white;
  background-color: var(--color-grey-deactivated);
}

.main-menu a.selected,
.main-menu button.selected {
  color: var(--color-blue2);
}

.main-menu a::before,
.main-menu button::before,
.main-menu a::after,
.main-menu button::after {
  color: var(--color-blue2);
  display: inline-block;
  width: 20px;
  color: var(--color-blue2);
  content: " ";
}

.main-menu a.selected::before,
.main-menu button.selected::before {
  content: "→ ";
}
