.highscore-list-container .highscore-filter {
  font-size: 18px;
  margin-bottom: 20px;
  text-align: center;
}

.highscore-list-container h1 {
  position: relative;
  right: -5px;
}

.highscore-list-container .highscore-filter button {
  color: var(--color-blue-dark);
  margin: 0 10px;
}

.highscore-list-container .highscore-filter button.selected {
  color: var(--color-yellow);
}

.highscore-list-container .no-entries {
  color: var(--color-yellow);
  text-align: center;
}

/* highscore list */
.highscore-list-container .list {
  display: grid;
  column-gap: 10px;
  row-gap: 10px;
  grid-template-columns: auto auto auto auto minmax(0, 1fr) auto auto;
  color: var(--color-blue-dark);
  align-items: baseline;
}

.highscore-list-container .list .place {
  text-align: right;
  color: white;
}

.highscore-list-container .list .minimap {
  align-self: center;
}

.highscore-list-container .list .place-1 {
  font-size: 40px;
  color: var(--color-yellow) !important;
  margin-bottom: 15px;
}

.highscore-list-container .list .you {
  animation: blink 1s ease-in-out infinite;
}

.highscore-list-container .list .your-last-score {
  color: var(--color-yellow);
}

.highscore-list-container .list .alias,
.highscore-list-container .list .emoji {
  text-transform: uppercase;
  color: var(--color-blue-darker);
}

.highscore-list-container .list .emoji {
  text-align: right;
}

.highscore-list-container .list .name-time .name {
  word-wrap: break-word;
}

.highscore-list-container .list .name-time span.time {
  display: inline-block;
  font-size: 15px;
  opacity: 0.5;
}

.highscore-list-container .list .link {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 5px;
}

.highscore-list-container .list .score {
  justify-self: right;
  color: white;
}

.highscore-list-container .list a {
  color: var(--color-blue-darker);
}

.highscore-list-container .list a:hover {
  color: var(--color-blue-dark);
}

@keyframes blink {
  50% {
    opacity: 0.2;
  }
}

@media screen and (max-width: 500px) {
  .highscore-list-container .list .minimap {
    align-self: baseline;
  }

  .highscore-list-container .list .name-time .name {
    max-width: 80px;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
